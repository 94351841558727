
.react-calendar__month-view__weekdays__weekday {
    color: #29BCBE;
}

abbr {
    text-decoration: none;
    text-transform: capitalize;
}

/* Modifica el color del texto para los números de día */
.react-calendar__tile {
    color: #000000;
    text-align: center;
    padding: 5px 0;
}

/* Modifica el color de fondo del día seleccionado */
.react-calendar__tile--active {
    background-color: #29BCBE;
    border-radius: 10px;
    color: #ffffff;
}

/* Modifica el color de fondo del día de hoy */
.react-calendar__tile--now {
    background-color: #29bbbe6d;
    border-radius: 10px;
    color: #000000;
}

.react-calendar__navigation {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.rbc-month-row {

}

.rbc-event {
    width: fit-content;
    margin: auto;
    margin-top: 10px;
    border-radius: 12px;
    background-color: #DDE8ED !important;
    pointer-events: none !important;
    
}

.rbc-off-range-bg{
    background-color: #949899 !important;
}

.rbc-today{
    background-color: #29CEBE !important;
}
