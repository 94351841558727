.carousel {
    display: flex;
    width: 100%;
    height: 20rem;
    align-items: center;
    font-family: Arial;
}

.carousel__list {
    display: flex;
    list-style: none;
    position: relative;
    width: 100%;
    height: 20rem;
    justify-content: center;
    perspective: 300px;
}

.carousel__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
    width: 13rem;
    height: 20rem;
    padding: 0.5rem;
    border-radius: 12px;
    box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.5);
    position: absolute;
    transition: all .3s ease-in;
    background-color: #fff;
}

.carousel__item[data-pos="0"] {
    z-index: 5;
    background: #202B37;
    color: #fff;
}

.carousel__item[data-pos="oculto"] {
    visibility: hidden;
}

.carousel__item[data-pos="-1"],
.carousel__item[data-pos="1"] {
    opacity: 0.7;
    filter: blur(1px) grayscale(10%);
}

.carousel__item[data-pos="-1"] {
    transform: translateX(-100%) scale(.9);
    z-index: 4;
}

.carousel__item[data-pos="1"] {
    transform: translateX(100%) scale(.9);
    z-index: 4;
}

.carousel__item[data-pos="-2"],
.carousel__item[data-pos="2"] {
    opacity: 0.4;
    filter: blur(3px) grayscale(20%);
}

.carousel__item[data-pos="-2"] {
    transform: translateX(-190%) scale(.8);
    z-index: 3;
}

.carousel__item[data-pos="2"] {
    transform: translateX(190%) scale(.8);
    z-index: 3;
}