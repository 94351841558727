@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  font-family: "Inter", sans-serif;
}

*, *:before, *:after {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  list-style: none;
}


.dropdown {
  position: relative;
  color: #333;
  cursor: default;
  justify-content: center;
  width: 100%;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
 
  
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 90%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
  z-index: 1000;
}

.dropdown .option {
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #202B37;
  color: white;
}







