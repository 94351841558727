.contenedor_principal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #202B37;
  width: 100vw;
  display: flex;
  font-size: 1.2rem;
  height: auto;
  min-height: 100vh;
  max-width: 100vw;
}

.sidebar {
  width: 20%;
  min-height: 90vh;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 0.5rem;
  overflow-y: auto;
}

.sidebar.small {
  width: 8%;
  min-width: 100px;
}

.contenedor_logo_y_usuario {
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.logo {
  min-width: 200px;
  width: 80%;
  cursor: pointer;
}

.small_logo {
  min-width: 100px;
  width: 100px;
  cursor: pointer;
}

.usuario {
  margin-top: 3rem;
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.text {
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

.nav_btn.hide_text .btn_text,
.hidden {
  display: none;
}



.nav_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background-color: transparent;
  width: 92%;
  font-size: 1.2rem;
  color: white;
  margin: 1rem 0;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.375rem;

  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

}

.nav_btn:hover {
  color: #202B37;
  background-color: #29CEBE;
}

.iconos {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  min-width: 30px;
}

.nav_btn.hide_text .iconos {
  margin: auto 0;
}

.sidebar.small .iconos {
  margin: auto;
}

.submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #29CEBE;
  width: 90%;
  margin: auto;
  border-radius: 20px;
  padding: 1rem 0;
}

.submenu_btn {
  width: 90%;
  margin: auto;
  color: #202B37;
}

.submenu_btn:hover {
  background-color: #202B37;
  color: white;
}

.contenedor_derecho {
  flex: 1;
  display: flex;
}


@media (max-width: 640px) {

  .usuario {
    margin: auto
  }

  .contenedor_superior_sidebar {
    display: flex;
    justify-content: space-around;
  }

  .contenedor_principal {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    min-height: fit-content;
  }

  .nav_btn {
    margin: 0.5rem;
  }

  .sidebar.small .contenedor_salir .nav_btn {
    display: none;
  }

  .sidebar .contenedor_salir .nav_btn {
    width: 50%;
  }

  .sidebar.small {
    width: 100%;
    min-height: fit-content;

  }

  .menu {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .menu .nav_btn {
    width: 50%;
  }

  .sidebar.small .menu {

    display: none;
  }

  .sidebar.small .menu .nav_btn {
    width: auto;
  }

  .submenu .nav_btn {
    width: 90%;
  }

  .submenu {
    width: 80%;
    min-width: 50px;
  }

  .contenedor_superior_sidebar {
    display: flex;
  }

  .contenedor_derecho {
    align-items: baseline;
  }

  .contenedor_derecho>div {
    height: 80vh;
    margin: 1rem;
    width: 95vw;
  }
}