/* Modifica los colores del texto para los días de la semana */
.react-calendar__month-view__weekdays__weekday {
    color: aqua;
}

abbr {
    text-decoration: none;
    text-transform: capitalize;
}

/* Modifica el color del texto para los números de día */
.react-calendar__tile {
    color: #ffffff;
    text-align: center;
    padding: 5px 0;
}

/* Modifica el color de fondo del día seleccionado */
.react-calendar__tile--active {
    background-color: aqua;
    border-radius: 10px;
    color: #000000;
}

/* Modifica el color de fondo del día de hoy */
.react-calendar__tile--now {
    background-color: rgba(147, 255, 255, 0.341);
    border-radius: 10px;
    color: #fff;
}

.react-calendar__navigation {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}